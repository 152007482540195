import { Box } from '@mui/system';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, Fade, Button } from '@mui/material';

import { ReactComponent as SuccessSvg } from '../../assets/images/success.svg';

import { resetState } from '../../redux/form';

import styles from './RecoverPasswordSuccess.module.scss';

export const RecoverPasswordSuccess = () => {
    const dispatch = useDispatch();

    const restart = () => {
        dispatch(resetState());
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='h1'>Email resetare parolă trimis cu succes.</Typography>

                    <Box className={styles.svg}>
                        <SuccessSvg />
                    </Box>

                    <Typography variant='body' className={styles.description}>
                        Ți-am trimis un e-mail care conține link-ul de resetare parolă.
                    </Typography>

                    <Typography variant='bold' className={styles.info}>
                        După modificarea parolei, nu uita să revii în această pagină pentru completarea formularului de
                        înregistrare.
                    </Typography>
                </Box>

                <Box className={styles.nextButton}>
                    <Button variant='contained' fullWidth onClick={restart}>
                        Spre ecranul de început
                    </Button>
                </Box>
            </Box>
        </Fade>
    );
};
