import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import SnackbarService from '../helpers/snackbar';
import { APP_CONFIG, DEFAULT_IQOS_TYPE, PRODUCT_TYPES, STARTING_STEP } from '../helpers/config';
import axiosInstance, { errorHandler } from '../helpers/axios';

const initialState = {
    stepId: STARTING_STEP,
    stepHistory: [],
    uniqueCode: '',
    loading: false,
    checkEmailResult: {
        // email: 'tester029@yopmail.com',
        email: '',
        status: '',
        // pending_lead_data: {
        //     limit_reached: true,
        // },
    },
    loginResult: {},
    registerLAUResult: {},
    registerLASResult: {},
    registerLAUApiResult: {},
    qrCode: false,
    productType: '',
    // productType: PRODUCT_TYPES.iqos,
    iqosType: DEFAULT_IQOS_TYPE,
    posInfo: null,
    phoneNumberValidation: null,
    phone: null,
};

// pending_lead_data.is_loyalty = 1 => LAU
// pending_lead_data.is_loyalty = 0 => LAS

/**
 * info related decision for https://grapefruitdigital.atlassian.net/browse/PTQR-431
 * comment the flow where users chooses a to claim a reward or get a discount
 */

export const checkEmail = createAsyncThunk('/users/search', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/users/search', { params: values });
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const validatePhoneNumber = createAsyncThunk(
    '/users/check-phone-number',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get('/users/check-phone-number', { params: values });
            return response.data;
        } catch (e) {
            return errorHandler(e, rejectWithValue);
        }
    },
);

export const refreshEmail = createAsyncThunk('refresh/users/search', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/users/search', { params: values });
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const login = createAsyncThunk('/users/login', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/users/login', values);
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const registerLAS = createAsyncThunk('/users/register-las', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/users/register-las', values);
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const recoverPassword = createAsyncThunk('/users/forgot-password', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/users/forgot-password', values);
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const resendActivateDeviceEmail = createAsyncThunk(
    '/users/resend-activation-email',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/users/resend-activation-email', values);
            return response.data;
        } catch (e) {
            return errorHandler(e, rejectWithValue);
        }
    },
);

export const registerLAU = createAsyncThunk('/users/register-lau', async (values, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosInstance.post('/users/register-lau', values);
        const { sku_model } = response.data;
        
        dispatch(setRegisterLAUResult({...values, sku_model}));
        
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const redeemOnline = createAsyncThunk('/users/redeem-online', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/users/redeem-online', values);
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const uniqueCodeCheck = createAsyncThunk('/users/check-unique-code', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/users/check-unique-code');
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const downloadShopsList = createAsyncThunk('/users/download/shop-list', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get('/users/download/shop-list', {
            responseType: 'arraybuffer',
        });
        const filename = 'lista-magazine.pdf';
        let blob = new Blob([response.data]);
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        SnackbarService.toast('Fișierul a fost descărcat cu succes.');
        return {};
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const downloadRegulation = createAsyncThunk(
    '/users/download/organic-sales-regulation',
    async (values, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get('/users/download/organic-sales-regulation', {
                responseType: 'arraybuffer',
            });
            const filename = 'regulament-campanie.pdf';
            let blob = new Blob([response.data], { type: 'application/pdf' });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, filename);
                SnackbarService.toast('Fișierul a fost descărcat cu succes.');
            } else {
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            }

            return {};
        } catch (e) {
            return errorHandler(e, rejectWithValue);
        }
    },
);

export const checkPosCode = createAsyncThunk('/pos/{cod}', async (values, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`/pos/${values.id}`);
        return response.data;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

const form = createSlice({
    name: 'form',
    initialState,
    reducers: {
        resetState: () => initialState,
        setStepId: (state, { payload }) => {
            state.stepHistory.push(current(state).stepId);
            state.stepId = payload;
        },
        setRegisterLAUResult: (state, { payload }) => {
            state.registerLAUResult = payload;
        },
        goBack: (state) => {
            const currentHistory = [...current(state).stepHistory];
            if ([APP_CONFIG.steps.WELCOME_LAU, APP_CONFIG.WELCOME_LAU_INACTIVE].includes(current(state).stepId)) {
                state.stepHistory = [];
                state.stepId = STARTING_STEP;
                state.checkEmailResult = {};
                state.loginResult = {};
                return;
            }
            state.stepId = currentHistory.slice(-1)[0];
            state.stepHistory = currentHistory.slice(0, currentHistory.length - 1);
        },
        setUniqueCode: (state, { payload }) => {
            state.uniqueCode = payload;
        },
        setQrCode: (state) => {
            state.qrCode = true;
        },
        setProductType: (state, { payload }) => {
            state.productType = payload;
            state.stepHistory.push(current(state).stepId);
            state.stepId = APP_CONFIG.steps.CHECK_VIDEO;
        },
        setStoreIqosType: (state, { payload }) => {
            state.iqosType = payload;
        },
        setPosInfo: (state, { payload }) => {
            state.posInfo = payload;
        },
    },
    extraReducers: (builder) => {
        //validatePhoneNumber
        builder
            .addCase(validatePhoneNumber.fulfilled, (state, { payload }) => {
                state.phoneNumberValidation = payload;
            })
            .addCase(uniqueCodeCheck.fulfilled, (state, { payload }) => {
                state.phone = payload.phone_number;
            })
            .addCase(checkEmail.fulfilled, (state, { payload }) => {
                state.checkEmailResult = payload;
                state.stepHistory.push(current(state).stepId);

                if (!payload.isPhoneValidated) {
                    return;
                }

                if (payload.pending_lead_data.form_state === 'inactive_redeemed') {
                    state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;
                    return;
                }

                if (
                    payload.pending_lead_data.form_state === 'submitted' &&
                    payload.pending_lead_data.confirmed_account == false
                ) {
                    state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;
                    return;
                }

                if (payload.pending_lead_data.is_loyalty === 1 || payload.pending_lead_data.is_loyalty === true) {
                    // LAU
                    // if (payload.account_status && payload.account_status !== 'A') {
                    //     state.stepId = APP_CONFIG.steps.WELCOME_LAU_INACTIVE;
                    //     return;
                    // }

                    state.stepId = APP_CONFIG.steps.CHECK_PASSWORD;
                    return;
                }

                if ([0, false].includes(payload.pending_lead_data.is_loyalty)) {
                    if (
                        payload.pending_lead_data.form_state === 'submitted' ||
                        payload.pending_lead_data.user_type === 'LAU'
                    ) {
                        state.stepId = APP_CONFIG.steps.CHECK_PASSWORD;
                        return;
                    }

                    state.stepId = APP_CONFIG.steps.REGISTER_LAS;
                    return;
                }

                //TODO  to check is loyalty == null
                if (payload.pending_lead_data.is_loyalty === null) {
                    if (payload.pending_lead_data.user_type === 'LAS') {
                        if (payload.pending_lead_data.form_state === 'submitted') {
                            state.stepId = APP_CONFIG.steps.CHECK_PASSWORD;
                            return;
                        }

                        state.stepId = APP_CONFIG.steps.REGISTER_LAS;
                        return;
                    }

                    if (payload.pending_lead_data.user_type === 'LAU') {
                        if (payload.account_status && payload.account_status !== 'A') {
                            state.stepId = APP_CONFIG.steps.WELCOME_LAU_INACTIVE;
                            return;
                        }

                        state.stepId = APP_CONFIG.steps.CHECK_PASSWORD;
                        return;
                    }
                }
            })
            .addCase(refreshEmail.fulfilled, (state, { payload }) => {
                // this action is called in the LAS case when the user activated his account.
                state.checkEmailResult = payload;
                // testing purposes
                // state.checkEmailResult.pending_lead_data.pos_code = '123456';
                if (payload.account_status === 'A') {
                    state.stepHistory.push(current(state).stepId);
                    if (payload.pending_lead_data.limit_reached) {
                        state.stepId = APP_CONFIG.steps.LIMIT_REACHED;
                        return;
                    }

                    if (payload.pending_lead_data.device_code === null) {
                        state.stepId = APP_CONFIG.steps.WELCOME_LAU;
                        return;
                    }

                    state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;
                    return;
                }
                SnackbarService.toast('Contul tău nu a fost activat.');
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.loginResult = payload;
                state.stepHistory.push(current(state).stepId);
                const currentState = current(state);
                // payload.profile_status = "active";

                if (payload.profile_status === 'active') {
                    if (currentState.checkEmailResult.pending_lead_data.limit_reached) {
                        state.stepId = APP_CONFIG.steps.WARNING_LIMIT_REACHED;
                        return;
                    }

                    if (currentState.checkEmailResult.pending_lead_data.form_state === 'submitted') {
                        state.stepId = APP_CONFIG.steps.CONGRATS_SHOP;
                        return;
                    }

                    state.stepId = APP_CONFIG.steps.WELCOME_LAU;
                    return;
                }

                state.stepId = APP_CONFIG.steps.WELCOME_LAU_INACTIVE;
            })
            .addCase(registerLAU.fulfilled, (state, { payload }) => {
                state.registerLAUApiResult = payload;
                state.stepHistory.push(current(state).stepId);
                if (current(state).checkEmailResult.pending_lead_data.limit_reached) {
                    state.stepId = APP_CONFIG.steps.LIMIT_REACHED;
                    return;
                }

                if (payload.confirmed_account) {
                    state.stepId = APP_CONFIG.steps.CONGRATS_SHOP;

                    return;
                }

                state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;
            })
            .addCase(recoverPassword.fulfilled, (state) => {
                state.stepHistory.push(current(state).stepId);
                state.stepId = APP_CONFIG.steps.RECOVER_PASSWORD_SUCCESS;
            })
            .addCase(registerLAS.fulfilled, (state, { payload }) => {
                state.registerLASResult = payload;
                state.stepHistory.push(current(state).stepId);

                state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;
            })
            .addCase(redeemOnline.fulfilled, (state, { payload }) => {
                state.stepHistory.push(current(state).stepId);
                if (payload.form_state === 'inactive_redeemed') {
                    state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;

                    return;
                }
                state.stepId = APP_CONFIG.steps.CONGRATS_DISCOUNT;
            })
            .addCase(checkPosCode.fulfilled, (state, { payload }) => {
                state.posInfo = payload;
            })
            .addCase(checkPosCode.rejected, (state) => {
                state.posInfo = null;
            });
    },
});

export const {
    resetState,
    setStepId,
    goBack,
    setRegisterLAUResult,
    setUniqueCode,
    setQrCode,
    setProductType,
    setStoreIqosType,
    setPosInfo,
} = form.actions;

export default form.reducer;
